.text,
.textSemi,
.textBold {
    font-weight: normal;
    font-size: 14px;
    color: white;
}

.text {
    font-family: Montserrat;
}

.textSemi {
    font-family: Montserrat-SemiBold;
}

.textBold {
    font-family: Montserrat-Bold;
}