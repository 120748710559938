@media (max-width: 1024px) /* Mobile Normal */
and (orientation: portrait) {
    .header-root div {
        padding: 0 0 0 40px;
        gap: 45px;
    }
}

@media (max-width: 600px) /* Mobile Normal */
and (orientation: portrait) {
    .header-root div {
        padding: 0 0 0 20px;
        gap: 35px;
    }
}

@media (max-width: 375px) /* Mobile Extra Small */
and (orientation: portrait) {
    .header-root div {
        padding: 0 0 0 20px;
        gap: 40px;
    }
}