footer {
    width: 100%;
    height: fit-content;
    background-color: rgb(20, 20, 20, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding: 10px;
    gap: 100px;
    backdrop-filter: blur(6px);
}

.footer-data {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

#footer-date {
    font-size: 16px;
    margin: 0 auto auto 0;
}

.prayer-times {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 10px;
}

.prayer-times div {
    display: flex;
    flex-direction: column;
}

.prayer-times div h4 {
    color: rgb(230, 183, 1);
    font-size: 17px;
    margin: 0;
}

.prayer-times div h3 {
    font-size: 18px;
    margin: 0;
}

.btn-footer {
    gap: 30px;
}

#footer-warning {
    margin: 0;
}

@media (max-width: 1024px)
and (orientation: portrait),
((max-width: 1114px) and (orientation: landscape)),
((width: 720px) and (orientation: landscape)) {
    .btn-footer {
        display: none;
    }
}

@media (max-height: 600px) /* Mobile Landscape */
and (orientation: landscape) {
    footer {
        display: none;
    }
}
