@media (max-width: 900px) /* Landscape Tab Normal */
and (orientation: landscape) {
    #footer-date {
        font-size: 14px;
    }
    .prayer-times div h4 {
        font-size: 17px;
    }
    .prayer-times div h3 {
        font-size: 17px;
    }
    #footer-warning {
        font-size: 13px;
    }
}

@media (max-width: 800px) /* Tab Normal */
and (orientation: portrait) {
    #footer-date {
        font-size: 15px;
    }
    .prayer-times div h4 {
        font-size: 15px;
    }
    .prayer-times div h3 {
        font-size: 15px;
    }
    #footer-warning {
        font-size: 14px;
    }
}

@media (max-width: 700px) /* Tab Small */
and (orientation: portrait) {
    #footer-date {
        font-size: 12px;
    }
    .prayer-times div h4 {
        font-size: 12.5px;
    }
    .prayer-times div h3 {
        font-size: 12.5px;
    }
    #footer-warning {
        font-size: 11px;
    }
}

@media (max-width: 600px) /* Tab Small */
and (orientation: portrait) {
    #footer-date {
        font-size: 12px;
    }
    .prayer-times div h4 {
        font-size: 11px;
    }
    .prayer-times div h3 {
        font-size: 11px;
    }
    #footer-warning {
        font-size: 11px;
    }
}

@media (max-width: 480px) /* Mobile Normal */
and (orientation: portrait) {
    #footer-date {
        font-size: 11px;
    }
    .prayer-times div h4 {
        font-size: 11px;
    }
    .prayer-times div h3 {
        font-size: 11px;
    }
    #footer-warning {
        font-size: 10px;
    }
}

@media (max-width: 375px) /* Mobile Small */
and (orientation: portrait) {
    #footer-date {
        font-size: 11px;
    }
    .prayer-times div h4 {
        font-size: 11px;
    }
    .prayer-times div h3 {
        font-size: 11px;
    }
    #footer-warning {
        font-size: 10px;
    }
}

@media (max-width: 320px) /* Mobile Extra Small */
and (orientation: portrait) {
    #footer-date {
        font-size: 10px;
    }
    .prayer-times div h4 {
        font-size: 11.5px;
    }
    .prayer-times div h3 {
        font-size: 11.5px;
    }
    #footer-warning {
        font-size: 10px;
    }
}