.time-counter {
    width: 100%;
    height: 100%;
    display: table-row;
}

.time-counter-parent {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.time-counter-parent h2 {
    text-shadow: 1.5px 1.5px rgba(40, 40, 40, 0.4);
}

#counter-date {
    font-size: 27px;
    margin-top: 0px;
}

#counter-next {
    font-size: 25px;
    margin-top: -15px;
}

#counter-timeTitle {
    font-size: 30px;
    margin-top: -5px;
}

#counter-timeTime {
    font-size: 30px;
    margin-top: -25px;
}

#counter-next2 {
    font-size: 20px;
}

#counter-location {
    font-size: 25px;
    margin-top: -5px;
    padding-left: 20px;
    padding-right: 20px;
}

.counter-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 0 10px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.btn-location {
    width: 38%;
}

.btn-derma {
    display: none;
    width: 35%;
}

@media (max-width: 1024px) and (orientation: portrait),
((max-width: 1024px) and (orientation: landscape)),
((max-width: 375px) and (orientation: portrait)) {
    .btn-derma {
        display: flex;
    }
}