#dialog-root {
  width: 100vw;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  display: none;
}

@keyframes backdropIn {
  from {
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0px);
  }
  to {
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(3px);
  }
}

@keyframes backdropOut {
  from {
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(3px);
  }
  to {
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0px);
    display: none;
  }
}

#dialog-parent {
  width: 65%;
  height: calc(100% - var(--navHeight));
  margin: var(--navHeight) auto 0 auto;
  left: 0;
  right: 0;
  display: none;
  position: absolute;
}

#dialog-card {
  width: 100%;
  height: 98%;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@keyframes cardIn {
  0% {
      scale: 0.2;
      top: 100%;
  }
  100% {
      scale: 1;
      top: 0;
  }
}

@keyframes cardOut {
  0% {
      scale: 1;
      top: 0;
  }
  100% {
      scale: 0.4;
      top: 100vh;
  }
}

.location-header { /* Header */
  height: 60px;
  position: relative;
  border-bottom: 1px solid var(--colorLine);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding: 0 15px;
}

.location-header h3 { /* Title */
  font-size: 20px;
  margin: 0;
  color: rgb(40, 40, 40);
}

.location-header img { /* Button Close */
  width: 26px;
  height: 26px;
}

.location-header img:active {
  opacity: 0.6;
}

.location-body { /* Body */
  height: 100%;
  padding-left: 15px;
  display: flex;
  flex-direction: column;
}

#location-container { 
  height: calc(100% - 60px);
  overflow-y: auto;
  padding-right: 15px;
}

#location-container input { /* Search View */
  width: 100%;
  margin: 10px 0 0 0;
  box-sizing: border-box;
  border: 1.5px solid rgb(230, 230, 230);
  border-radius: 8px;
  font-size: 16px;
  background-color: rgb(240, 240, 240);
  background-image: url('../../../../public/icons/ic_search.svg');
  background-size: 25px 25px;
  background-position: 8px 8px;
  background-repeat: no-repeat;
  padding: 10px 5px 10px 40px;
  outline: none;
  color: rgb(40, 40, 40);
}

#location-container input::placeholder {
  color: rgb(180, 180, 180);
}

#location-container input:focus { /* Search View Focus */
  border: 1.5px solid rgb(163, 214, 155);
  background-image: url('../../../../public/icons/ic_search_focus.svg');
}

@media (hover: hover) {
  .location-header img:hover {
    cursor: pointer;
    opacity: 0.6;
  }
}