.left-root {
    width: 120px;
    height: 100%;
    background-color: rgb(20, 20, 20, 0.6);
    display: none;
    flex-direction: column;
    backdrop-filter: blur(6px);
}

.left-item {
    width: 100%;
    height: calc(100% / 7);
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 0.8px solid rgb(100, 100, 100);
}

.isIsyak {
    border-bottom: 0;
}

.left-item div {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.left-item div h4 {
    color: orange;
    font-size: 16px;
    margin: 0;
}

.left-item div h3 {
    font-size: 17px;
    margin: 0;
}