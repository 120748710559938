@media (max-width: 800px) /* Tab Normal */
and (orientation: portrait) {
    :root {
        --countdownBackgroundSize: 130px;
        --countdownBackgroundBorder: 3px;
    }
    .countdown-center {
        margin: 0 25px;
    }
    .countdown-root div h1 {
        font-size: 65px;
        text-shadow: 2px 2px rgba(40, 40, 40, 0.4);
    }
    .countdown-root div h4 {
        font-size: 20px;
    }
}

@media (max-height: 700px) /* Landscape Tab Normal */
and (orientation: landscape) {
    :root {
        --countdownBackgroundSize: 110px;
        --countdownBackgroundBorder: 2.5px;
    }
    .countdown-root div h1 {
        font-size: 60px;
        text-shadow: 2px 2px rgba(40, 40, 40, 0.4);
    }
    .countdown-root div h4 {
        font-size: 16px;
    }
}

@media (max-width: 600px) /* Tab Small */
and (orientation: portrait) {
    :root {
        --countdownBackgroundSize: 120px;
        --countdownBackgroundBorder: 2.5px;
    }
    .countdown-center {
        margin: 0 20px;
    }
    .countdown-root div h1 {
        font-size: 60px;
    }
    .countdown-root div h4 {
        font-size: 18px;
        margin-top: -10px;
    }
}

@media (max-width: 480px) /* Mobile Normal */
and (orientation: portrait) {
    :root {
        --countdownBackgroundSize: 90px;
        --countdownBackgroundBorder: 2px;
    }
    .countdown-center {
        margin: 0 15px;
    }
    .countdown-root div h1 {
        font-size: 42px;
    }
    .countdown-root div h4 {
        font-size: 13px;
        margin-top: -5px;
    }
}

@media (max-height: 480px) /* Landscape Mobile Normal */
and (orientation: landscape) {
    :root {
        --countdownBackgroundSize: 85px;
        --countdownBackgroundBorder: 2px;
    }
    .countdown-center {
        margin: 0 20px;
    }
    .countdown-root div h1 {
        font-size: 42px;
    }
    .countdown-root div h4 {
        font-size: 14px;
        margin-top: -5px;
    }
}

@media (max-width: 375px) /* Mobile Extra Small */
and (orientation: portrait) {
    :root {
        --countdownBackgroundSize: 80px;
        --countdownBackgroundBorder: 2px;
    }
    .countdown-center {
        margin: 0 15px;
    }
    .countdown-root div h1 {
        font-size: 40px;
    }
    .countdown-root div h4 {
        font-size: 12px;
        margin-top: -5px;
    }
}

@media (max-height: 375px) /* Landscape Mobile Small */
and (orientation: landscape) {
    :root {
        --countdownBackgroundSize: 70px;
        --countdownBackgroundBorder: 2px;
    }
    .countdown-center {
        margin: 0 15px;
    }
    .countdown-root div h1 {
        font-size: 36px;
    }
    .countdown-root div h4 {
        font-size: 12px;
        margin-top: -5px;
    }
}

@media (max-width: 320px) /* Mobile XSmall */
and (orientation: portrait) {
    :root {
        --countdownBackgroundSize: 80px;
        --countdownBackgroundBorder: 1.5px;
    }
    .countdown-center {
        margin: 0 10px;
    }
    .countdown-root div h1 {
        font-size: 38px;
    }
    .countdown-root div h4 {
        font-size: 13px;
        margin-top: -5px;
    }
}