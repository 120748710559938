@media (max-width: 1024px) /* Tab Medium */
and (orientation: portrait) {
    #dialog-parent {
        width: 80%;
    }
}

@media (max-width: 800px) /* Tab Normal */
and (orientation: portrait) {
    #dialog-parent {
        width: 90%;
    }
}

@media (max-width: 800px) /* Landscape Mobile */
and (orientation: landscape) {
    #dialog-parent {
        width: 85%;
    }
}

@media (max-height: 700px) /* Landscape Mobile Medium */
and (orientation: landscape) {
    #dialog-parent {
        height: calc(100% - 55px); /* Header: 55px */
        margin-top: 55px;
    }
    .location-header {
        height: 50px;
    }
    .location-header h3 {
        font-size: 18px;
    }
    .location-header img {
        font-size: 22px;
    }
    #location-container { 
        height: calc(100% - 50px);
    }
}

@media (max-width: 600px) /* Mobile Medium */
and (orientation: portrait) {
    #dialog-parent {
        width: 95%;
        height: calc(100% - 50px); /* Header: 50px */
        margin-top: 50px;
    }
    .location-header {
        height: 50px;
    }
    .location-header h3 {
        font-size: 18px;
    }
    .location-header img {
        font-size: 20px;
    }
    #location-container { 
        height: calc(100% - 50px);
    }
}

@media (max-height: 500px) and (orientation: landscape),
((max-width: 375px) and (orientation: portrait)) {
    #dialog-parent {
        height: calc(100% - 40px); /* Header: 40px */
        margin-top: 40px;
    }
    .location-header {
        height: 40px;
    }
    .location-header h3 {
        font-size: 14px;
    }
    .location-header img {
        font-size: 18px;
    }
    .location-body {
        padding-left: 10px;
    }
    #location-container input {
        margin: 8px 0 0 0;
        font-size: 13px;
        background-size: 19px;
        border-radius: 6px;
        padding: 8px 5px 8px 35px;
    }
    #location-container { 
        height: calc(100% - 40px);
    }
}

@media (max-height: 375px) /* Landscape Mobile Small */
and (orientation: landscape) {
    #dialog-parent {
        height: calc(100% - 36px); /* Header: 36px */
        margin-top: 36px;
    }
    .location-header {
        height: 40px;
    }
    .location-header h3 {
        font-size: 15px;
    }
    .location-header img {
        font-size: 16px;
    }
    #location-container input {
        margin: 8px 0 0 0;
        font-size: 13px;
        background-size: 19px;
        border-radius: 6px;
        padding: 8px 5px 8px 35px;
    }
    #location-container { 
        padding-right: 10px;
    }
    #location-container { 
        height: calc(100% - 40px);
    }
}

@media (max-width: 375px) /* Mobile Small */
and (orientation: portrait) {
    .location-header {
        height: 40px;
    }
    .location-header h3 {
        font-size: 14px;
    }
    .location-header img {
        font-size: 16px;
    }
    .location-body {
        padding-left: 10px;
    }
    #location-container input {
        margin: 8px 0 0 0;
        font-size: 13px;
        background-size: 19px;
        border-radius: 6px;
        padding: 8px 5px 8px 35px;
    }
    #location-container { 
        padding-right: 10px;
    }
    #location-container { 
        height: calc(100% - 40px);
    }
}









