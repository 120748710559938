.App {
  min-height: 100%;
  min-width: 100%;
  padding: 0;
  margin: 0;
}

.app-parent {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.app-parent-content {
  height: 100%;
  display: flex;
  flex-grow: 1;
  margin: 0;
  padding: 0;
}

.table {
  display: table;
  width: 100%;
  height: 100%;
}

.app-header {
  height: var(--navHeight);
}

.app-content {
  height: 100%;
}

.app-footer {
  height: 0px;
}

.row {
  display: table-row;
}

/* Font Initializing */ 
@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  src: local('Montserrat'), url(../fonts/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat-Italic';
  font-weight: 400;
  src: local('Montserrat'), url(../fonts/Montserrat-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  font-weight: 600;
  src: local('Montserrat'), url(../fonts/Montserrat-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat-SemiBold-Italic';
  font-weight: 600;
  src: local('Montserrat'), url(../fonts/Montserrat-MediumItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat-Bold';
  font-weight: 700;
  src: local('Montserrat'), url(../fonts/Montserrat-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat-BoldItalic';
  font-weight: 700;
  src: local('Montserrat'), url(../fonts/Montserrat-BoldItalic.ttf) format('truetype');
}

/* Colors */
:root {
  --colorLine: rgb(240, 240, 240);
  --colorShimmerBase: rgb(230, 230, 230);
  --colorShimmer: rgb(245, 245, 245);

  --navHeight: 70px;
}