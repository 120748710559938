@media (max-height: 700px) /* Landscape Mobile Medium */
and (orientation: landscape) {
    :root {
        --navHeight: 55px;
    }
}

@media (max-width: 600px) /* Mobile Medium */
and (orientation: portrait) {
    :root {
        --navHeight: 50px;
    }
}

@media (max-height: 600px) /* Landscape Mobile Medium */
and (orientation: landscape) {
    .app-footer {
        display: none;
    }
}

@media (max-height: 500px) and (orientation: landscape),
((max-width: 375px) and (orientation: portrait)) {
    :root {
        --navHeight: 40px;
    }
}

@media (max-height: 375px) /* Landscape Mobile Small */
and (orientation: landscape) {
    :root {
        --navHeight: 36px;
    }
}