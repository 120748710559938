.slider-root {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.slider-parent {
    width: 100%;
    height: 100%;
    position: absolute;
    display: none;
}

.slideShow {
    display: block;
    animation: slideShow 1.8s linear;
}

@keyframes slideShow {
    0% {
        opacity: 0;
        scale: 1.05;
    }

    100% {
        opacity: 1;
        scale: 1;
    }
}

.slideHide {
    animation: slideHide 1.5s linear;
}

@keyframes slideHide {
    0% {
        opacity: 1;
        scale: 1;
    }

    100% {
        opacity: 0;
        scale: 1.02;
    }
}

.slider-image {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
}