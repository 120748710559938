.button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border-radius: 9px;
    border: 2px solid orange;
    color: rgb(230, 183, 1);
    padding: 9px 8px 9px 9px;
    cursor: pointer;
    font-family: Montserrat-Bold;
    font-weight: normal;
    font-size: 15px;
}

.button img {
    width: 25px;
    height: 25px;
}

.button:active {
    background-color: rgb(235, 235, 235);
}

@media (max-width: 899px) {
    .button {
        border-radius: 7px;
        border: 1.5px solid orange;
        padding: 8px 6px 8px 8px;
        font-size: 13px;
    }
    .button img {
        width: 20px;
        height: 20px;
    }
}

@media (max-width: 480px) {
    .button {
        padding: 7px 6px 7px 7px;
        font-size: 11px;
    }
    .button img {
        width: 22px;
        height: 22px;
    }
}

@media (max-height: 480px)
and (orientation: landscape) {
    .button {
        border-radius: 6px;
        border: 1.3px solid orange;
        padding: 6px 5px 6px 6px;
        font-size: 11px;
    }
    .button img {
        width: 18px;
        height: 18px;
    }
}

@media (hover: hover) {
    .button:hover {
        background-color: rgb(235, 235, 235);
    }
}