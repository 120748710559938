@media (max-width: 1024px) /* Nest Hub */
and (max-height: 600px)
and (orientation: landscape) {
    .left-root {
        display: flex;
    }
}

@media (max-height: 480px) /* Mobile Normal */
and (orientation: landscape) {
    .left-root {
        display: flex;
        width: 100px;
    }
    .left-item div h4 {
        font-size: 12px;
    }
    .left-item div h3 {
        font-size: 13px;
    }
}

@media (max-height: 375px) /* Mobile Small */
and (orientation: landscape) {
    .left-root {
        display: flex;
        width: 90px;
    }
    .left-item div h4 {
        font-size: 11px;
    }
    .left-item div h3 {
        font-size: 12px;
    }
}