.countdown-root {
    display: flex;
    justify-content: center;
    margin-top: 12px;
}

.countdown-root div {
    width: var(--countdownBackgroundSize);
    height: var(--countdownBackgroundSize);
    background-color: rgb(245, 245, 245, 0.4);
    border-radius: var(--countdownRadius);
    border: var(--countdownBackgroundBorder) solid orange;
    backdrop-filter: blur(1px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.countdown-center {
    margin: 0 30px;
}

.countdown-root div h1 {
    font-size: 75px;
    margin: 0;
    text-shadow: 2.5px 2.5px rgba(40, 40, 40, 0.4);
}

.countdown-root div h4 {
    font-size: 20px;
    margin: -10px 0 0 0;
    text-shadow: 1.5px 1.5px rgba(40, 40, 40, 0.4);
}

:root {
    --countdownBackgroundSize: 140px;
    --countdownBackgroundBorder: 4px;
    --countdownRadius: calc(var(--countdownBackgroundSize) / 1.95);
}