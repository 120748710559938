@media (max-height: 900px) /* Mobile Medium */
and (orientation: landscape) {
    .header-root div {
        padding: 0 0 0 35px;
        gap: 25px;
    }
}

@media (width: 1024px) /* Nest Hub */
and (height: 600px)
and (orientation: landscape) {
    .header-root h2 {
        display: block;
    }
}

@media (max-height: 500px) /* Mobile Normal */
and (orientation: landscape) {
    .header-root div {
        padding: 0 0 0 30px;
        gap: 20px;
    }
    .header-root h2 {
        display: block;
    }
}

@media (max-height: 375px) /* Mobile Small */
and (orientation: landscape) {
    .header-root h2 {
        display: block;
    }
}