@media (max-width: 1024px) /* Tab Medium */
and (orientation: portrait) {
    .counter-button-container {
        flex-direction: column;
    }
    .btn-location {
        width: 65%;
    }
    .btn-derma {
        width: 48%;
    }
}

@media (max-width: 800px) /* Tab Normal */
and (orientation: portrait) {
    #counter-date {
        font-size: 20px;
    }
    #counter-next {
        font-size: 20px;
        margin-top: -5px;
    }
    #counter-timeTitle {
        font-size: 28px;
    }
    #counter-timeTime {
        font-size: 24px;
    }
    #counter-next2 {
        font-size: 18px;
        margin-top: 20px;
    }
    #counter-location {
        font-size: 22px;
        margin-top: 5px;
    }
    .counter-button-container {
        margin-top: 25px;
    }
}

@media (max-height: 700px) /* Landscape Tab Normal */
and (orientation: landscape) {
    #counter-date {
        font-size: 20px;
    }
    #counter-next {
        font-size: 18px;
    }
    #counter-timeTitle {
        font-size: 27px;
    }
    #counter-timeTime {
        font-size: 24px;
        margin-top: -15px;
    }
    #counter-next2 {
        font-size: 18px;
    }
    #counter-location {
        font-size: 22px;
    }
}

@media (max-width: 600px) /* Tab Small */
and (orientation: portrait) {
    #counter-date {
        font-size: 18px;
    }
    #counter-next {
        font-size: 18px;
        margin-top: -5px;
    }
    #counter-timeTitle {
        font-size: 25px;
    }
    #counter-timeTime {
        font-size: 22px;
    }
    #counter-next2 {
        font-size: 16px;
        margin-top: 20px;
    }
    #counter-location {
        font-size: 18px;
        margin-top: 5px;
    }
}

@media (max-width: 480px) /* Mobile Normal */
and (orientation: portrait) {
    #counter-date {
        font-size: 13px;
    }
    #counter-next {
        font-size: 14px;
        margin-top: -5px;
    }
    #counter-timeTitle {
        font-size: 22px;
    }
    #counter-timeTime {
        font-size: 19px;
    }
    #counter-next2 {
        font-size: 12px;
        margin-top: 20px;
    }
    #counter-location {
        font-size: 14px;
        margin-top: 0;
    }
    .counter-button-container {
        margin-top: 10px;
    }
    .btn-location {
        width: 90%;
    }
    .btn-derma {
        width: 70%;
    }
}

@media (max-height: 480px) /* Landscape Mobile Normal */
and (orientation: landscape) {
    #counter-date {
        font-size: 18px;
    }
    #counter-next {
        font-size: 19px;
        margin-top: -7px;
    }
    #counter-timeTitle {
        font-size: 22px;
    }
    #counter-timeTime {
        font-size: 20px;
        margin-top: -15px;
    }
    #counter-next2 {
        font-size: 14px;
        margin-top: 10px;
    }
    #counter-location {
        font-size: 16px;
        margin-top: -5px;
    }
    .counter-button-container {
        margin-top: 5px;
    }
    .btn-location {
        width: 50%;
    }
}

@media (max-width: 375px) /* Mobile Extra Small */
and (orientation: portrait) {
    #counter-date {
        font-size: 12px;
    }
    #counter-next {
        font-size: 12px;
        margin-top: -5px;
    }
    #counter-timeTitle {
        font-size: 19px;
        margin-top: -5px;
    }
    #counter-timeTime {
        font-size: 16px;
        margin-top: -10px;
    }
    #counter-next2 {
        font-size: 12px;
        margin-top: 10px;
    }
    #counter-location {
        font-size: 14px;
        margin-top: 5px;
    }
    .counter-button-container {
        margin-top: 10px;
    }
}

@media (max-height: 375px) /* Landscape Mobile Small */
and (orientation: landscape) {
    #counter-date {
        font-size: 13px;
    }
    #counter-next {
        font-size: 12px;
        margin-top: -7px;
    }
    #counter-timeTitle {
        font-size: 16px;
    }
    #counter-timeTime {
        font-size: 14px;
        margin-top: -10px;
    }
    #counter-next2 {
        font-size: 11px;
        margin-top: 10px;
    }
    #counter-location {
        font-size: 12px;
        margin-top: 5px;
    }
    .counter-button-container {
        margin-top: 5px;
    }
    .btn-location {
        width: 50%;
    }
}

@media (max-width: 320px) /* Mobile XSmall */
and (orientation: portrait) {
    #counter-date {
        font-size: 11px;
    }
    #counter-next {
        font-size: 12px;
    }
    #counter-timeTitle {
        font-size: 18px;
    }
    #counter-timeTime {
        font-size: 15px;
        margin-top: -10px;
    }
    #counter-next2 {
        font-size: 11px;
        margin-top: 10px;
    }
    #counter-location {
        font-size: 12px;
        margin-top: 5px;
    }
    .counter-button-container {
        margin-top: 10px;
    }
}

/* 720 540 */