.location-item-root {
    display: flex;
    flex-direction: column;
}

.location-item-root h3 {
    font-size: 17px;
    margin: 15px 0 8px 5px;
    color: black;
}

.location-item-root table {
    /* Change these properties */
    width: 100%;
    --border: 1px solid var(--colorLine);
    border-radius: 8px;
    margin-bottom: 30px;

    /* Don't change these properties */
    border-spacing: 0;
    border-collapse: separate;
    border: var(--border);
    overflow: hidden;
}

.location-item-root table tr td {
    border-bottom: 1px solid var(--colorLine);
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    font-family: Montserrat-SemiBold;
}

.location-item-root table tr:last-child td {
    border-bottom: 0px;
}

.location-item-root table tr:hover {
    background-color: rgb(240, 240, 240);
    cursor: pointer;
}

.location-item-root table tr td div {
    display: flex;
    align-items: center;
}

#location-txt-code {
    width: 130px;
    font-size: 14px;
    margin: 0;
    color: rgb(100, 100, 100);
}

#location-txt-location {
    width: 100%;
    font-size: 15px;
    margin: 0;
    color: black;
}