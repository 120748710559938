@media (max-height: 500px)
and (orientation: landscape) {
    .location-item-root h3 {
        font-size: 15px;
    }
    #location-txt-code {
        width: 100px;
        font-size: 12px;
    }
    #location-txt-location {
        font-size: 13px;
    }
}

@media (max-width: 500px)
and (orientation: portrait) {
    .location-item-root h3 {
        font-size: 15px;
    }
    #location-txt-code {
        width: 100px;
        font-size: 12px;
    }
    #location-txt-location {
        font-size: 13px;
    }
}

@media (max-height: 375px)
and (orientation: landscape) {
    .location-item-root h3 {
        font-size: 14px;
    }
    #location-txt-code {
        width: 85px;
        font-size: 11px;
    }
    #location-txt-location {
        font-size: 12px;
    }
}

@media (max-width: 375px)
and (orientation: portrait) {
    .location-item-root h3 {
        font-size: 14px;
    }
    #location-txt-code {
        width: 85px;
        font-size: 11px;
    }
    #location-txt-location {
        font-size: 12px;
    }
}