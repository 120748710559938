.header-root {
    width: 100%;
    height: var(--navHeight);
    background-color: white;
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    padding: 0px;
}

.header-root div {
    width: fit-content;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 0 0 50px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    gap: 25px;
}

.header-root div img {
    width: var(--navLogoSize);
    height: var(--navLogoSize);
}

.header-root div h1 {
    font-size: var(--navTitleSize);
    color: rgb(60, 60, 60);
    margin: 0;
}

.header-root h2 {
    max-width: var(--navWarningWidth);
    color: gray;
    font-size: var(--navWarningText);
    padding-right: 5px;
    display: none;
}

:root {
    --navLogoSize: calc(var(--navHeight) / 1.2);
    --navTitleSize: calc(var(--navHeight) / 3.2);
    --navWarningWidth: calc(100vw / 4);
    --navWarningText: calc(var(--navHeight) / 4.5);
}

